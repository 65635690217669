//react
import {useCallback, useEffect, useState} from 'react';

//components
import Error from '../../../components/error/Error';
import Loading from '../../../components/loading/Loading';
import ViewPdf from '../../../components/viewPdf/ViewPdf';
import DatePickerComponent from '../../../components/datePicker/DatePickerComponent';

// paginate
import ReactPaginate from 'react-paginate';

//interface
import {IContract} from '../../../interfaces/IContract';
import {IProposalAccordSimulateDto} from '../../../interfaces/IProposalAccordSimulateDto';
import {IAccordSimulateResponseDto} from '../../../interfaces/IAccordSimulateResponseDto ';

//css
import styles from './AnticipationInstallments.module.css';

//services
import {ProposalAccordService} from '../../../services/proposalAccord/ProposalAccordService';
import {ContractService} from '../../../services/contract/ContractService';

//api
import {ApiException} from '../../../api/ApiException';

//utils
import {Toast} from '../../../utils/Toast';

//modal
import ModalAnticipationInstallments from './modalAnticipationInstallments/ModalAnticipationInstallments';

//moment
import moment from 'moment';

//icons
import {FaFileDownload} from 'react-icons/fa';
import {
    RiArrowDropLeftLine,
    RiArrowDropRightLine,
    RiArrowLeftDoubleFill,
    RiArrowRightDoubleLine,
} from 'react-icons/ri';

//utils
import {File} from '../../../utils/File';
import AnticipationTable from './anticipationTable/AnticipationTable';
import {HolidaysService} from '../../../services/holidays/HolidaysService';
import ErrorSuccess from '../../../components/errorSuccess/ErrorSuccess';

type Props = {
    id: string | undefined
    contract: IContract | null
}
const AnticipationInstallments: React.FC<Props> = ({id, contract}) => {
    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [messageError, setMessageError] = useState<string>('');
    const [isCassiop, setIsCassiop] = useState<boolean>(true);

    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    const [dataTable, setDataTable] = useState<any>();
    const [selected, setSelected] = useState<number[]>([]);
    const [selectedAll, setSelectedAll] = useState<boolean>(false);

    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const [dataSimulateConsult, setDataSimulateConsult] = useState<IProposalAccordSimulateDto>();
    const [accordSimulate, setAccordSimulate] = useState<IAccordSimulateResponseDto>();
    const [holidaysDate, setHolidaysDate] = useState<Date[]>([]);
    const [messageAntecipation, setMessageAntecipation] = useState<string>('');
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [allInstallmentNumber, setAllInstallmentNumber] = useState<number[]>([]);

    const onCloseModal = () => {
        setOpenModal(false);
    };

    const downloadPDF = () => {
        if (data && data?.base64 && data?.fileName) {
            File.downloadPdf(data?.base64, data?.fileName);
        }
    };

    const getAllInstallments = useCallback(async (status: string, competenceDate: string, page: number) => {
        setLoading(true);
        await ContractService.getAllInstallments({
            contractCode: contract?.contractCode,
            originCode: contract?.dataOrigin,
            dueDate: competenceDate ? moment(competenceDate).format('yyyy-MM-DD') : '',
            status: status,
            page: page,
            size: 10,
        }).then((response) => {
            if (response instanceof ApiException) {
                setHasError(true);
                setPageCount(0);
                setTotalElements(0);
                setDataTable([]);
                setLoading(false);
            } else {
                setHasError(false);
                setPageCount(response?.totalPages);
                setTotalElements(response?.totalElements);
                setDataTable(response?.content);
                if (page === 0) {
                    setAllInstallmentNumber(response?.listAllOpen);
                }
            }
            setLoading(false);
        });
    }, []);

    const handlePageClick = (data: any) => {
        setPage(data.selected);
        getAllInstallments('OPEN', '', data.selected);
    };

    const lastPage = () => {
        setPage(pageCount - 1);
        getAllInstallments('OPEN', '', pageCount - 1);
    };

    const firstPage = () => {
        setPage(0);
        getAllInstallments('OPEN', '', 0);
    };

    const consultDataSimulate = async () => {
        setLoading(true);
        ProposalAccordService.consultDataSimulate(contract?.cpfCnpj || '', contract?.contractCode || '').then((response) => {
            if (response instanceof ApiException) {
                Toast.error(response.message);
                setHasError(true);
                setMessageError(response.message);
                setLoading(false);
            } else {
                setHasError(false);
                setDataSimulateConsult(response);
                getAllInstallments('OPEN', '', 0);
            }
        });
    };

    const getAllHolidays = async () => {
        setLoading(true);
        HolidaysService.getAllHolidays().then((response) => {
            if (response instanceof ApiException) {
                console.log(response.message);
            } else {
                setHasError(false);
                setHolidaysDate(response.map(e => moment(e.date).toDate()));
            }
            setLoading(false);
        });
    };

    const simulate = async () => {
        setLoading(true);
        if (dataSimulateConsult) {
            dataSimulateConsult.accordOperations[0].installments = selected.map(number => ({number}));
            dataSimulateConsult.dueDateBegin = moment(selectedDate).format('yyyy-MM-DD');
            setDataSimulateConsult(dataSimulateConsult);
            ProposalAccordService.simulate(dataSimulateConsult).then((response) => {
                if (response instanceof ApiException) {
                    Toast.error(response.message);
                    setLoading(false);
                } else {
                    setAccordSimulate(response);
                    setOpenModal(true);
                    setLoading(false);
                }
            });
        }
    };

    useEffect(() => {
        getAllHolidays();
    }, []);

    useEffect(() => {
        if (selectedAll && allInstallmentNumber !== null) {
            setSelected(allInstallmentNumber);
        }
    }, [selectedAll]);

    useEffect(() => {
        if (selected.length > 0 && selected.length === totalElements) {
            setSelectedAll(true);
        }
        setTimeout(() => {
            const validationCanSimulate: boolean = (contract?.dataOrigin === "CASSIOPAE");
            setIsCassiop(validationCanSimulate);
            if (!validationCanSimulate) {
                consultDataSimulate();
            }
        }, 100)

    }, [selected]);

    return (
        <>
            {openModal && contract && accordSimulate && dataSimulateConsult && (
                <ModalAnticipationInstallments
                    contract={contract}
                    onCloseModal={onCloseModal}
                    open={openModal}
                    accordSimulate={accordSimulate}
                    dataSimulateConsult={dataSimulateConsult}
                    setData={setData}
                    consultDataSimulate={consultDataSimulate}
                    selectedAll={selectedAll}
                    setMessageAntecipation={setMessageAntecipation}
                />
            )}
            {loading && <Loading/>}
            {!hasError ? <div className={styles.container}>
                <div className={styles.top}>
                    <h2 className={styles.tittle}>Antecipação de parcelas</h2>
                    <div className={styles.informations}>
                        <label className={styles.information}>
                            Contrato
                            <strong style={{color: '#006930'}}>{contract?.contractCode}</strong>
                        </label>
                    </div>
                </div>
                <hr/>
                {!data && !isCassiop && (
                    <>
                        <div className={styles.filter}>
                            <div className={styles.filters}>
                                <DatePickerComponent
                                    selectedDate={selectedDate}
                                    setSelectedDate={setSelectedDate}
                                    initial={0}
                                    final={5}
                                    holidays={holidaysDate}
                                />
                                <input type="button" value="Simular" disabled={!(selected.length > 0 && selectedDate)}
                                       onClick={() => {
                                           if (selected.length > 0 && selectedDate)
                                               simulate();
                                       }}/>
                            </div>
                        </div>
                        <div className={styles.tableContainer}>
                            {dataTable && dataTable.length > 0 && (
                                <AnticipationTable
                                    data={dataTable}
                                    selected={selected}
                                    setSelected={setSelected}
                                    selectedAll={selectedAll}
                                    setSelectedAll={setSelectedAll}
                                />
                            )}
                        </div>
                    </>
                )}
                {isCassiop && (
                    <div className={styles.erroSuccess}>
                        <ErrorSuccess
                            message={"Este contrato não permite a antecipação de parcelas por este canal"}
                            showCallCenter={true}
                        />
                    </div>
                )}
                {!data && totalElements > 10 && (
                    <div className="paginationContainer">
                        <div
                            onClick={firstPage}
                            className={`first-page ${page === 0 ? 'disabled' : ''}`}>
                            <RiArrowLeftDoubleFill/>
                        </div>
                        <ReactPaginate
                            previousLabel={<div className={`first-page ${page === 0 ? 'disabled' : ''}`}>
                                <RiArrowDropLeftLine/></div>}
                            nextLabel={<div className={`last-page ${page + 1 === pageCount ? 'disabled' : ''}`}>
                                <RiArrowDropRightLine/></div>}
                            breakLabel={'...'}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            activeClassName={'active'}
                            activeLinkClassName={'active'}
                            forcePage={page}
                        />
                        <div
                            onClick={lastPage}
                            className={`last-page ${page + 1 === pageCount ? 'disabled' : ''}`}>
                            <RiArrowRightDoubleLine/>
                        </div>
                    </div>
                )}
                {data && (
                    <div>
                        <div className={styles.filter}>
                            {messageAntecipation && (
                                <div>
                                    <ErrorSuccess
                                        message={messageAntecipation}
                                        showCallCenter={true}
                                    />
                                </div>
                            )}
                            <div className={styles.filters}>
                                <input style={{marginBottom: '1em'}} type="button" value="Voltar" onClick={() => {
                                    setData(null);
                                }}/>
                            </div>
                            <div className={styles.actionButtons}>
                                {data.base64 && data.fileName &&
                                    <button className={styles.download} onClick={downloadPDF}><FaFileDownload/>Baixar
                                    </button>}
                            </div>
                        </div>
                        <div style={{width: '100%', paddingBottom: '56.25%', position: 'relative'}}>
                            <ViewPdf
                                base={data.base64}
                                fileName={data.fileName}
                            />
                        </div>
                    </div>
                )}
            </div> : <Error message={messageError}/>}
        </>
    );
};

export default AnticipationInstallments;